import React from "react";
//import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
//import Header2 from "components/Header/Header2";

const SiteHeader = () => {
  //let location = useLocation();

  return  <HeaderLogged />
 // return location.pathname === "/" ? <HeaderLogged /> : <Header2 />;
};

export default SiteHeader;
