import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import SiteHeader from "containers/SiteHeader";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";

import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import AccountPage from "containers/AccountPage/AccountPage";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import PageCollection from "containers/PageCollection";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";






export const pages: Page[] = [
  { path: "/", component: PageHome2 },
  { path: "/#", component: PageHome2 },
  { path: "/home2", component: PageHome },
  { path: "/home3", component: PageHome3 },
  { path: "/account", component: AccountPage },
  { path: "/nft-detailt", component: NftDetailPage },
  { path: "/page-upload-item", component: PageUploadItem },
  { path: "/page-collection", component: PageCollection },
  { path: "/connect-wallet", component: PageConnectWallet },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/subscription", component: PageSubcription },

  { path: "/home-header-2", component: PageHome },
  { path: "/connect-wallet", component: PageConnectWallet },


];

const MyRoutes = () => {
  return (
    <BrowserRouter
      basename={process.env.NODE_ENV === "production" ? "/" : "/"}
    >
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
